import { FeedItemResponse } from "@every.org/common/src/codecs/entities";
import { getLandingDataRouteSpec } from "@every.org/common/src/routes/publicCached";

import { InitialContextData } from "src/context/ContextProviders";
import { serversideStaticQueryApi } from "src/utility/apiClient";

export async function getLandingProps(): Promise<{
  initialData?: InitialContextData;
  feedItems: FeedItemResponse[];
}> {
  const landingFeed = await serversideStaticQueryApi(getLandingDataRouteSpec, {
    routeTokens: {},
    queryParams: {},
  });

  return {
    initialData: {
      tags: landingFeed.nonprofitTags,
      nonprofits: landingFeed.nonprofits,
      users: landingFeed.users,
    },
    feedItems: landingFeed.items,
  };
}
