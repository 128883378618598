import { NextPageContext } from "next";

import { getCauseProps } from "src/utility/apiClient/causePage";
import { getLandingProps } from "src/utility/apiClient/landingPage";

export type LandingInitialProps = Partial<
  Awaited<ReturnType<typeof getCauseProps>>
>;

export const getLandingPageInitialProps = async (
  ctx: NextPageContext
): Promise<LandingInitialProps> => {
  // Not on server, do nothing - let client-side data fetching handle this
  if (!ctx.res) {
    return {};
  }

  // Unlike most pages, do not support any cache as we ran into problems with
  // logged-in users being shown the logged-out landing page. Github issue #13287

  try {
    return await getLandingProps();
  } catch (error) {
    ctx.res.statusCode = 404;
    return {};
  }
};
